(function(window, document, $, undefined) {

	$(function() {

		/**
		 * Handle select placeholders
		 */
		$('select').on('change', function() {
			$(this).toggleClass('selected', $(this).val() != '');
		});

		/**
		 * Password viewer
		 */
		$('.jsPasswordViewer').on('click', function(e) {
			e.preventDefault();

			$(this).toggleClass('is_active');
			$(this).parent().find('input').attr('type', $(this).hasClass('is_active') ? 'text' : 'password');

		});

		/**
		 * Generic slider
		 */
		$('.jsGenericSliderWrapper').each(function() {

			var $wrapper 	= $(this);
			var $slider 	= $wrapper.find('.jsGenericSlider');
			var $nav		= $wrapper.find('[data-role=nav]');
			var items 		= $slider.data('items') || 1;
			var responsive  = [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: Math.min(3, items),
						centerMode: false,
						centerPadding: 0
					}
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: Math.min(2, items),
						centerMode: false,
						centerPadding: 0
					}
				},
				{
					breakpoint: 520,
					settings: {
						slidesToShow: 1,
						//centerMode: true,
						//centerPadding: '40px'
					}
				}
			];

			if ( $slider.data('mod') == 'vehicle' ) {

				responsive  = [
					{
						breakpoint: 1024,
						settings: {
							slidesToShow: Math.min(3, items),
							centerMode: false,
							centerPadding: 0
						}
					},
					{
						breakpoint: 768,
						settings: {
							slidesToShow: 2,
							centerMode: false,
							centerPadding: 0
						}
					},
					{
						breakpoint: 520,
						settings: {
							slidesToShow: 1,
							centerMode: true,
							centerPadding: '70px'
						}
					}
				];

			}

			$slider.slick({
				dots: 			false,
				arrows: 		false,
				slidesToShow: 	items,
				slidesToScroll: 1,
				infinite: 		false,
				centerMode: 	false,
				centerPadding: 	0,
				responsive: 	responsive
			});

			$nav.on('click', function(e) {
				return $slider.slick($(this).data('dir')), !1;
			});

		});

		$('.jsInstagramPostsSlider').each(function() {

			$(this).slick({
				slidesToShow: 8,
				dots: false,
				arrows: false,
				responsive: [
					{
						breakpoint: 1024,
						settings: {
							slidesToShow: 3,
							centerMode: true,
							centerPadding: '100px'
						}
					},
					{
						breakpoint: 768,
						settings: {
							slidesToShow: 2,
							centerMode: true,
							centerPadding: '100px'
						}
					},
					{
						breakpoint: 520,
						settings: {
							slidesToShow: 1,
							centerMode: true,
							centerPadding: '100px'
						}
					}
				]
			});

		});
	
		$('.vehicle-slider-nav--mobile a').on('click', function(e) {
			e.preventDefault();
			$('.vehicle-slider-nav--desktop a[data-dir="'+$(this).data('dir')+'"]').trigger('click');
		});

		$('.jsHeaderMenuToggler').on('click', function(e) {
			e.preventDefault();

			$('.jsOffcanvasMenuWrapper').toggleClass('is_active');
		});

		/**
		 * FAQ row expand
		 */
		$('.jsFAQHead').on('click', function(e) {
			e.preventDefault();

			$(this).find('.jsFAQTitle').toggleClass('is_active');
			$(this).parent().closest('.jsFAQRow').find('.jsFAQClose').toggleClass('is_active');
			$(this).parent().closest('.jsFAQRow').find('.jsFAQContent').slideToggle();
		});

		/**
		 * Anchor links
		 */
		$('a[href^=\\#]').on('click', function(e) {
			if ( $(this).attr('href') != '#' ) {
				e.preventDefault();

				var $elem = $($.attr(this, 'href'));
				if ( $elem.length > 0 ) {
					$('html, body').animate({
						scrollTop: $elem.offset().top
					}, 700);
				}
			}
		});

		$(window).resize(function() {

			$('.vehicle-slider-nav--desktop a').css({
				top: ( ($('.vehicle-card__thumb').height() / 2) - ($('.vehicle-slider-nav--desktop a').height() / 2) ) + 'px'
			});

		});

		$('.menu-item-expander').on('click', function(e) {
			e.preventDefault();
			e.stopPropagation();

			$(this).toggleClass('is_active');
			$(this).parent().parent().find('.offcanvas-sub-menu').slideToggle();
		});

		$('.main-header-toggler').on('click', function(e) {
			e.preventDefault();

			$(this).toggleClass('is_active');
			$('.offcanvas-menu').toggleClass('is_active');
		});

		$(window).on('resize', function() {

			if ( window.matchMedia('(min-width: 1366px)').matches ) {
				$('.main-header-toggler').removeClass('is_active');
				$('.offcanvas-menu').removeClass('is_active');
			}

		});

		// Headroom
		(new Headroom(document.querySelector(".main-header"))).init();

	}); /* ./jQuery.ready */

	/**
	 * Various helper functions
	 */

})(window, document, jQuery);
